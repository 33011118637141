import React from "react";
import { graphql, Link } from "gatsby";
import Image from "../components/Image";
import Cta from "../components/Cta";
import Nav from "../components/Nav";
import ArticleThumbnail from "../components/ArticleThumbnail";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import ImageTextBlock from "../components/ImageTextBlock";
import { CheckIcon } from "@heroicons/react/outline";
import Button from "../components/Button";
import BlockContent from "../components/BlockContent";
import Seo from "../components/Seo";
import CookieBanner from "../components/CookieBanner";
import Testimonial from "../components/Testimonial";

const IndexPage = ({
  data: {
    sanityLanding: {
      mainHeading,
      introduction,
      cta,
      mainImage,
      testimonialIntro,
      testimonials,
      valueProps,
      valuePropsImage,
      valuePropText,
      valuePropCta,
      articleIntroTitle,
      articleIntroText,
      imageAndTextBlock,
      faq,
      faqTitle,
      faqText,
      collaboratorText,
      collaborators,
      priceExample,
      loanBox1,
      loanBox2,
    },
    allSanityArticle,
  },
}) => {
  return (
    <>
      <Seo title={mainHeading} description={introduction} ogImage={mainImage} />
      <main>
        <Nav />
        <header className="grid lg:grid-cols-2 min-h-fold items-center px-page mx-auto gap-16 py-8 lg:min-h-128">
          <div>
            <h1 className="text-heading-4 md:text-heading-2 text-gray-dark mb-2">
              {mainHeading}
            </h1>
            <p className="text-body-1">
              <BlockContent blocks={introduction} />
            </p>
            <div className="grid md:grid-cols-2 grid-cols-1 items-center">
              <div className="place-self-center">
                <Cta cta={cta} />
              </div>
              <div className="place-self-center">
                <Button href={"/gjeldsforhandling"}>Økonomisk restart</Button>
              </div>
            </div>
          </div>
          <Image image={mainImage} alt="Ryddi" className="rounded shadow-lg" />
        </header>
        {testimonials && testimonials.length > 0 && (
          <section className="text-center mb-16 px-page">
            <h2 className="mb-4 text-subheading-1">{testimonialIntro}</h2>
            <div className="flex justify-center gap-4 flex-wrap ">
              {testimonials.map((testimonial) => (
                <Testimonial {...testimonial} />
              ))}
            </div>
          </section>
        )}
        <section className="grid lg:grid-cols-2 bg-gray-dark py-16 px-page flex items-center gap-x-16 border-b-4 border-t-4 border-accent">
          <Image
            image={valuePropsImage}
            className="rounded shadow-lg w-full"
            alt="Illustrasjonsfoto"
          />
          <div className="">
            <h2 className="text-heading-4 text-white mt-4">{valuePropText}</h2>
            <ul className="text-white mt-4">
              {valueProps.map((text) => (
                <li className="flex items-center" key={text}>
                  <CheckIcon className="w-8 h-8 mr-4" />
                  {text}
                </li>
              ))}
            </ul>
            <Cta cta={valuePropCta} className="mt-4" />
          </div>
        </section>
        <section className="px-8 md:px-page mt-16">
          <div className="text-center max-w-screen-lg mx-auto mb-24">
            <BlockContent
              blocks={articleIntroTitle}
              className="text-heading-3 mb-4"
            />
            <BlockContent blocks={articleIntroText} />
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-8">
            {allSanityArticle.edges.map(({ node: article }, index) => (
              <ArticleThumbnail {...article} key={"article-" + index} />
            ))}
          </div>
          {allSanityArticle.totalCount > 3 && (
            <div className="flex justify-center">
              <Button to="/artikler" type="secondary">
                Les flere artikler
              </Button>
            </div>
          )}
        </section>
        <ImageTextBlock {...imageAndTextBlock} />
        <section className="text-center mt-32">
          <h4 className="text-subheading-2">{collaboratorText}</h4>
          <div className="flex flex-col md:flex-row md:space-x-4 justify-center mt-4 items-center">
            {collaborators.map(({ image, url }, index) => (
              <a
                href={url}
                target="_blank"
                rel="noreferrer noopener"
                key={"samarbeidspartner # " + index}
              >
                <Image
                  image={image}
                  className="w-48"
                  alt={"Ryddi samarbeidspartner #" + index}
                />
              </a>
            ))}
          </div>
        </section>
        <section className="max-w-screen-lg mx-auto mt-24 md:px-page px-4">
          <h3 className="text-heading-4 text-center mb-4">{faqTitle}</h3>
          <p className="text-body-1 text-center mb-8">{faqText}</p>
          {faq.map((faqItem, index) => (
            <Faq {...faqItem} key={"faq" + index} />
          ))}
        </section>
        <p className="mt-16 text-center text-gray text-caption max-w-screen-lg px-page mx-auto">
          {priceExample}
        </p>

        <div className="flex flex-col md:flex-row place-content-between px-16">
          <div className="p-4 text-center">
            <BlockContent blocks={loanBox1} />
          </div>
          <div className="p-4 text-center md:border-l-2 border-accent">
            <BlockContent blocks={loanBox2} />
          </div>
        </div>
        <Footer />
        <CookieBanner />
      </main>
    </>
  );
};
export const query = graphql`
  query {
    sanityLanding(_id: { regex: "/landing$/" }) {
      mainHeading
      introduction: _rawIntroduction
      loanBox1: _rawLoanBox1
      loanBox2: _rawLoanBox2
      cta: _rawCta(resolveReferences: { maxDepth: 1 })
      mainImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      testimonialIntro
      testimonials {
        highlight
        description
        slug {
          current
        }
      }
      valuePropText
      valueProps
      valuePropsImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      valuePropCta: _rawValuePropCta(resolveReferences: { maxDepth: 1 })
      articleIntroTitle: _rawArticleIntroTitle
      articleIntroText: _rawArticleIntroText
      imageAndTextBlock {
        title
        text: _rawText
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 800)
          }
        }
      }
      collaboratorText
      collaborators {
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 400)
          }
        }
        url
      }
      faqTitle
      faqText
      faq: _rawFaq
      priceExample
    }
    allSanityArticle(sort: { order: DESC, fields: publishedAt }, limit: 3) {
      totalCount
      edges {
        node {
          _key
          title
          author {
            name
          }
          publishedAt
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 800)
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`;
export default IndexPage;
