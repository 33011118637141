import React, { useState } from "react";
import { PlusIcon, MinusIcon } from "@heroicons/react/outline";

const Faq = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="w-full mt-4 border-b border-gray">
      <button
        onClick={() => setOpen(!open)}
        className="flex justify-between w-full text-left text-subheading-2 cursor-pointer select-none p-2 rounded transition-colors hover:bg-white"
      >
        <span>{question}</span>
        {!open ? (
          <PlusIcon className="h-6 w-6" />
        ) : (
          <MinusIcon className="h-6 w-6" />
        )}
      </button>
      <p
        className={
          "transform text-body-1 p-0 pl-2 pr-16 pb-4 " +
          (open ? "" : "h-0 opacity-0 pointer-events-none")
        }
      >
        {answer}
      </p>
    </div>
  );
};

export default Faq;
