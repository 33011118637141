import { Link } from "gatsby";
import React from "react";

const Testimonial = ({ highlight, description, slug }) => {
  return (
    <Link
      to={`historie/${slug.current}`}
      className="bg-white rounded shadow-lg p-6 text-center flex flex-col items-center max-w-md hover:shadow-xl transition-shadow"
    >
      <svg
        width="33"
        height="32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.733 22.667c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 19.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Zm-12 0c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 7.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Z"
          fill="#0FF"
        />
      </svg>
      <p className="text-subheading-2">{highlight}</p>
      <p className="mt-2 mb-4">{description}</p>
      <p className="text-gray">Les hele historien</p>
    </Link>
  );
};

export default Testimonial;
