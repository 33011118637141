import React from "react";
import BlockContent from "./BlockContent";
import Image from "./Image";

const ImageTextBlock = ({ title, text, image }) => {
  return (
    <section className="grid lg:grid-cols-2 gap-16 px-page my-16 lg:mt-32 items-center">
      <Image
        image={image}
        className="rounded shadow-lg lg:block none"
        aspectRatio={3 / 4}
        alt=""
      />
      <div>
        <h3 className="text-heading-4">{title}</h3>
        <BlockContent className="text-body-1 mt-4" blocks={text} prose />
      </div>
    </section>
  );
};

export default ImageTextBlock;
